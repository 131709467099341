import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Button } from "gatsby-material-ui-components";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import formatTitle from "../../components/common/formatTitle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SimpleNewsletterForm from "../../components/common/SimpleNewsletterForm";
import Carousel from "../../components/common/Carousel";
import Layout from "../../components/layout/layout";
import Cyclope2 from "./images/carousel_1.png";
import Cyclope3 from "./images/carousel_2.jpg";
import Cyclope4 from "./images/carousel_3.png";
import Article1 from "./images/article1.jpg";
import Article2 from "./images/article2.jpg";
import Article3 from "./images/article3.jpg";
import Article4 from "./images/article4.jpg";
import Article5 from "./images/article5.jpg";
import Article6 from "./images/article6.jpg";
import Article7 from "./images/article7.jpg";
import Article8 from "./images/article8.jpg";
import Article9 from "./images/article9.jpg";
import Article10 from "./images/article10.jpg";
import Article11 from "./images/article11.jpeg";
import Article12 from "./images/article12.jpeg";
import RadioVinci from "./images/radio_vinci.png";
import NiceMatin from "./images/nicematin.png";
import UsineDigitale from "./images/usine_digitale.png";
import UsineNouvelle from "./images/usine_nouvelle.png";
import Jdn from "./images/jdn.jpg";
import Challenges from "./images/challenges.png";
import AutoPlus from "./images/auto_plus.png";
import Alliancy from "./images/alliancy.png";
import Accordion from "./accordion";
import Highlights from "./highlights";
import PressKit from "./images/press_kit.png";
import BrandAsset from "./images/brand_asset.png";
import Manifesto from "./images/manifesto.png";

import Ceo from "./ceo";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularHoriz: {
    paddingTop: "4%",
    paddingBottom: "4%",
  },
  paddingRegularFirstRow: {
    padding: theme.layout.paddingFirstRow,
  },
  paddingRegularLastRow: {
    padding: theme.layout.paddingLastRow,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  blockCentered: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  withBg: {
    backgroundColor: theme.palette.background.medium,
    // backgroundColor: "red",
  },
  centered: {
    textAlign: "center",
  },
  paragLimit: {
    maxWidth: "75%",
  },
  enLight: {
    color: theme.palette.primary.light,
  },
  imgRnd: {
    borderRadius: "12px",
  },
  newsletter: {
    minWidth: "60%",
    marginTop: theme.spacing(3),
  },
  bookCeo: {
    marginTop: theme.spacing(6),
    fontWeight: 600,
    marginBottom: theme.spacing(8),
  },
  paddingFavoriteTopics: {
    padding: "7% 10% 0 10%",
  },
  paddingCeo: {
    padding: "7% 0 0 0",
  },
  paddingNextCeo: {
    paddingTop: "10%",
    paddingBottom: "10%",
  },
  big: {
    fontSize: "6em",
  },
  topics: {
    marginTop: theme.spacing(4),
  },
  roundBtnLeft: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginLeft: theme.spacing(1),
  },
  roundBtnRight: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: theme.spacing(1),
  },
}));

const MediaPress = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [direction, setDirection] = useState(1);
  const [slideIndex, setSlideIndex] = useState(0);
  const handleAfterSlide = (idx) => setSlideIndex(idx);

  useEffect(() => {
    setTimeout(() => handleAfterSlide(1), 3000);
  }, []);

  useEffect(() => {
    const nextIndex = slideIndex + direction;
    if (slideIndex === 3) {
      setDirection(-1);
    }
    if (slideIndex === 0 && direction === -1) {
      setDirection(1);
    }

    setTimeout(() => handleAfterSlide(nextIndex), 3000);
  }, [slideIndex]);

  const contents = data.allMediaPressJson.nodes[0].mediaPress[langKey][0];

  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item>
        <Grid container direction="column">
          <Grid
            item
            container
            spacing={2}
            direction="column"
            alignItems="center"
            className={clsx({
              [classes.paddingRegularFirstRow]: matches,
              [classes.paddingRegularMobile]: !matches,
              [classes.centered]: true,
            })}
          >
            <Grid item>
              <Typography
                variant="h1"
                className={clsx(
                  { [classes.paragLimit]: matches, [classes.big]: matches },
                  classes.blockCentered
                )}
              >
                {formatTitle(contents.love.title)}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {contents.love.contents[0]}
                <br />
                {contents.love.contents[1]}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size={matches ? "large" : "medium"}
                to={`/${langKey}/contact`}
              >
                {contents.love.btnLabel}
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            className={clsx({
              [classes.paddingRegular]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Carousel
              images={[Cyclope2, Cyclope3, Cyclope4]}
              withoutControls={false}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={clsx({
              [classes.paddingFavoriteTopics]: matches,
              [classes.paddingRegularMobile]: !matches,
              [classes.centered]: true,
              [classes.withBg]: true,
            })}
            spacing={8}
          >
            <Grid item>
              <Typography
                variant="h1"
                className={clsx(
                  { [classes.paragLimit]: true },
                  classes.blockCentered
                )}
              >
                {formatTitle(contents.topics.title)}
              </Typography>
              <Typography
                variant="body1"
                className={clsx(
                  classes.topics,
                  { [classes.paragLimit]: matches },
                  classes.blockCentered
                )}
              >
                {contents.topics.contents[0]}
                <br />
                {contents.topics.contents[1]}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                width: matches ? "80%" : "95%",
                margin: "auto",
                textAlign: "left",
              }}
            >
              <Accordion items={contents.topics.items} />
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.bookCeo}
                to={`/${langKey}/contact`}
                style={{ textAlign: matches ? "inherit" : "center" }}
              >
                {contents.topics.btnLabel}
              </Button>
            </Grid>
          </Grid>

          <Highlights
            btnLabel={contents.highlight.btnLabel}
            title={contents.highlight.title}
            highlights={[
              {
                language: "Français",
                date: "9 Février 2023",
                title:
                  "Autosolisme: les Français toujours de plus en plus nombreux à rouler seuls en voiture",
                image: Article10,
                source: NiceMatin,
                logoHeight: 40,
                url:
                  "https://www.nicematin.com/faits-divers/autosolisme-les-francais-toujours-de-plus-en-plus-nombreux-a-rouler-seuls-en-voiture-827012",
              },
              {
                language: "Français",
                date: "21 Septembre 2022",
                title:
                  "Duplex A86, un tunnel pour tester des véhicules autonomes et connectés",
                image: Article11,
                source: UsineDigitale,
                logoHeight: 40,
                url:
                  "https://www.usine-digitale.fr/article/duplex-a86-un-tunnel-pour-tester-des-vehicules-autonomes-et-connectes.N2045862",
              },
              {
                language: "Français",
                date: "24 Avril 2022",
                title: "L'autosolisme concerne 8 conducteurs sur 10",
                image: Article12,
                source: RadioVinci,
                logoHeight: 40,
                url:
                  "https://radio.vinci-autoroutes.com/article/l-autosolisme-concerne-8-conducteurs-sur-10-6602?rgpd=ok",
              },
              {
                language: "Français",
                date: "8 Février 2021",
                title: "Radars de covoiturage : souriez, vous êtes comptés !",
                image: Article1,
                source: AutoPlus,
                logoHeight: 40,
                url:
                  "https://www.autoplus.fr/actualite/radars-de-covoiturage-souriez-vous-etes-comptes-254387",
              },
              {
                language: "Français",
                date: "8 Décembre 2020",
                title:
                  "Cyclope.ai : l’Intelligence Artificielle pour améliorer la mobilité routière",
                image: Article2,
                source: Challenges,
                logoHeight: 40,
                url:
                  "https://www.challenges.fr/economie/cyclope-ai-l-intelligence-artificielle-pour-ameliorer-la-mobilite-routiere_740844",
              },
              {
                language: "Français",
                date: "4 Décembre 2020",
                title: "Cyclope.ai, l'œil qui scrute les autoroutes de Vinci",
                image: Article3,
                source: Jdn,
                logoHeight: 40,
                url:
                  "https://www.journaldunet.com/economie/services/1496139-cyclope-ai-l-oeil-qui-scrute-les-autoroutes-de-vinci/",
              },
              {
                language: "Français",
                date: "17 Juillet 2020 ",
                title:
                  "Un radar de covoiturage bientôt sur le périph' parisien !",
                image: Article4,
                source: AutoPlus,
                logoHeight: 40,
                url:
                  "https://www.autoplus.fr/securite-routiere/radar-bientot-un-dispositif-destine-a-traquer-les-adeptes-du-transport-partage-232643",
              },
              {
                language: "Français",
                date: "24 Avril 2020",
                title: "Un nouveau radar testé sur l'A7",
                image: Article5,
                source: AutoPlus,
                logoHeight: 40,
                url:
                  "https://www.linkedin.com/feed/update/urn:li:activity:6665615485335293952/",
              },
              {
                language: "Français",
                date: "28 Août 2019",
                title:
                  "L'intelligence artificielle en 35 cas d'usages : VINCI dope à l'IA la gestion des feux de signalisation",
                image: Article6,
                source: UsineNouvelle,
                logoHeight: 25,
                url:
                  "https://www.usinenouvelle.com/article/vinci-dope-a-l-ia-la-gestion-des-feux-de-signalisation.N877380",
              },
              {
                language: "Français",
                date: "17 Juin 2019 ",
                title:
                  "Comment VINCI Autoroutes veut contribuer au développement des véhicules autonomes",
                image: Article7,
                source: UsineDigitale,
                logoHeight: 25,
                url:
                  "https://www.usine-digitale.fr/article/comment-vinci-autoroutes-veut-contribuer-au-developpement-des-vehicules-autonomes.N855920",
              },
              {
                language: "Français",
                date: "31 Mai 2019",
                title:
                  "Paris s'appuie sur les données pour piloter sa politique environnementale : la preuve en 3 projets",
                image: Article8,
                source: UsineNouvelle,
                logoHeight: 25,
                url:
                  "https://www.usinenouvelle.com/article/paris-s-appuie-sur-les-donnees-pour-piloter-sa-politique-environnementale-la-preuve-en-trois-projets.N848945",
              },
              {
                language: "Français",
                date: "10 Octobre 2018",
                title:
                  "Pour ancrer une innovation IA dans l’opérationnel, il faut la mettre en production le plus vite possible",
                image: Article9,
                source: Alliancy,
                logoHeight: 40,
                url:
                  "https://www.alliancy.fr/paul-maarek-vinci-autoroutes-pour-ancrer-une-innovation-ia-dans-loperationnel-il-faut-la-mettre-en-production-le-plus-vite-possible",
              },
            ]}
          />
        </Grid>
        <Grid
          item
          className={clsx({
            [classes.paddingRegularHoriz]: matches,
            [classes.paddingRegularMobile]: !matches,
          })}
          container
          direction="row"
          justify="center"
        >
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginBottom: "3%" }}
          >
            <Typography
              variant="h1"
              // className={clsx(
              //   { [classes.paragLimit]: matches },
              //   classes.blockCentered
              // )}
            >
              {contents.media.title}
            </Typography>
            <Typography
              variant="h6"
              color="textPrimary"
              // className={clsx(classes.blockCentered)}
            >
              {contents.media.content}
            </Typography>
          </Grid>
          <Grid
            item
            xs={matches ? 8 : 12}
            container
            direction="row"
            spacing={2}
          >
            <Grid item xs={4}>
              <img
                src={BrandAsset}
                alt="Press kit"
                width="100%"
                height="auto"
              />
              {matches && (
                <div style={{ textAlign: "left" }}>
                  <Typography
                    variant="h5"
                    style={{ marginTop: theme.spacing(3), fontWeight: 600 }}
                  >
                    {contents.media.items[0].title}
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {contents.media.items[0].subTitle}
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              <img src={Manifesto} alt="Press kit" width="100%" height="auto" />
              {matches && (
                <div style={{ textAlign: "left" }}>
                  <Typography
                    variant="h5"
                    style={{ marginTop: theme.spacing(3), fontWeight: 600 }}
                  >
                    {contents.media.items[1].title}
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {contents.media.items[1].subTitle}
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              <img src={PressKit} alt="Press kit" width="100%" height="auto" />
              {matches && (
                <div style={{ textAlign: "left" }}>
                  <Typography
                    variant="h5"
                    style={{ marginTop: theme.spacing(3), fontWeight: 600 }}
                  >
                    {contents.media.items[2].title}
                  </Typography>
                  {matches && (
                    <Typography variant="h6" color="textPrimary">
                      {contents.media.items[2].subTitle}
                    </Typography>
                  )}
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginTop: theme.spacing(3) }}
            >
              <Button
                variant="contained"
                color="primary"
                size={matches ? "large" : "medium"}
                to={`/${langKey}/contact?object=press`}
              >
                {contents.media.btnLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={classes.paddingCeo}
          container
          direction="column"
          justify="center"
        >
          <Ceo citation={contents.ceo.content} langKey={langKey} />
        </Grid>
        <Grid
          item
          className={clsx({
            [classes.paddingNextCeo]: true,
            [classes.paddingRegularLastRow]: matches,
            [classes.paddingRegularMobile]: !matches,
            [classes.withBg]: true,
            [classes.centered]: true,
          })}
          container
          direction="column"
          justify="center"
        >
          <Grid item>
            <Typography variant="h1" className={classes.blockCentered}>
              {formatTitle(contents.tuned.title)}
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.blockCentered}
            >
              {contents.tuned.content}
            </Typography>
          </Grid>
          <Grid
            item
            className={clsx(classes.blockCentered, classes.newsletter)}
          >
            <SimpleNewsletterForm
              successMessage="Inscription effectuée"
              buttonLabel="S’inscrire"
              large={matches}
              xsInput={matches ? 10 : 8}
              justify="center"
              langKey={langKey}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default MediaPress;

export const query = graphql`
  query MediaPressQueryFr {
    allMediaPressJson {
      nodes {
        mediaPress {
          fr {
            tuned {
              content
              title
            }
            topics {
              contents
              title
              btnLabel
              items {
                content
                subTitle
                title
              }
            }
            media {
              btnLabel
              content
              items {
                subTitle
                title
              }
              title
            }
            love {
              btnLabel
              contents
              title
            }
            highlight {
              btnLabel
              title
            }
            ceo {
              content
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
